<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('https://downloads.acs.com.hk/page-headers/2017-corporate-profile.jpg?5fc8a9b6a0943');
        "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title">Why Us </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container corporate-profile">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="page-content">
            <div id="corporate-profile-content">
              <p>
                We provide business transformative solutions since 1996. At TBS
                we have one common goal
              </p>
              <h5>Our Company Mission</h5>
              <p>
                we focus on Business Solutions and assist customers to transform
                their business operation from one stage to another greater
                height.
              </p>
              <p>
                While all customers may reach their goals at the early stage and
                may achieve greater heights by developing through each
                subsequent stage.
              </p>
              <p>
                Customers can click to visit the knowledge base, reach out our
                support team, or continue learn in our online clinic. The
                process of digital transformation, is a continuous business
                growth mindset. With our Creative and Innovative mindset will
                help us find out the cost-effective way to optimize and growth
                the business together.
              </p>
              <p>
                At last, we make sure our solutions Easy-to-use, Effective and
                Reliable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyUs"
};
</script>
